<template>
  <div>
    <Q11Desktop class="hidden md:block" @storeProgress="storeProgress" />
    <Q11Mobile class="block md:hidden" @storeProgress="storeProgress" />
  </div>
</template>

<script>
import Q11Desktop from "@/components/Experiences/Pulse1Week/Questions/Assets/Q10Desktop.vue";
import Q11Mobile from "@/components/Experiences/Pulse1Week/Questions/Assets/Q10Mobile.vue";

export default {
  components: {
    Q11Desktop,
    Q11Mobile,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    storeProgress(data) {
      this.$emit("storeProgress", data);
    },
  },
};
</script>

<style scoped></style>
