<template>
  <div>
    <Q2Book @storeProgress="storeProgress" />
  </div>
</template>

<script>
import Q2Book from "@/components/Experiences/Pulse1Week/Questions/Assets/Q2Book.vue";

export default {
  components: {
    Q2Book,
  },
  methods: {
    storeProgress(data) {
      this.$emit("storeProgress", data);
    },
  },
};
</script>

<style scoped></style>
