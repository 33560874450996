<template>
  <div>
    <Q14Desktop class="hidden md:block" @storeProgress="storeProgress" />
    <Q14Mobile class="block md:hidden" @storeProgress="storeProgress" />
  </div>
</template>

<script>
import Q14Desktop from "@/components/Experiences/Pulse1Week/Questions/Assets/Q13Desktop.vue";
import Q14Mobile from "@/components/Experiences/Pulse1Week/Questions/Assets/Q13Mobile.vue";

export default {
  components: {
    Q14Desktop,
    Q14Mobile,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    storeProgress(data) {
      this.$emit("storeProgress", data);
    },
  },
};
</script>

<style scoped></style>
