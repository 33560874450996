<template>
  <div>
    <component
      :is="'Question' + question"
      :qData="qData"
      @storeProgress="storeProgress"
    >
    </component>
  </div>
</template>

<script>
import Question1 from "@/components/Experiences/Pulse1Week/Questions/Question1.vue";
import Question2 from "@/components/Experiences/Pulse1Week/Questions/Question2.vue";
import Question3 from "@/components/Experiences/Pulse1Week/Questions/Question3.vue";
import Question4 from "@/components/Experiences/Pulse1Week/Questions/Question4.vue";
import Question5 from "@/components/Experiences/Pulse1Week/Questions/Question5.vue";
import Question6 from "@/components/Experiences/Pulse1Week/Questions/Question6.vue";
import Question7 from "@/components/Experiences/Pulse1Week/Questions/Question7.vue";
import Question8 from "@/components/Experiences/Pulse1Week/Questions/Question8.vue";
import Question9 from "@/components/Experiences/Pulse1Week/Questions/Question9.vue";
import Question10 from "@/components/Experiences/Pulse1Week/Questions/Question10.vue";
import Question11 from "@/components/Experiences/Pulse1Week/Questions/Question11.vue";
import Question12 from "@/components/Experiences/Pulse1Week/Questions/Question12.vue";
import Question13 from "@/components/Experiences/Pulse1Week/Questions/Question13.vue";

export default {
  name: "PulseQuestions",
  props: {
    question: {
      type: String,
      required: true,
    },
    qData: {
      required: true,
    },
  },
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
    Question7,
    Question8,
    Question9,
    Question10,
    Question11,
    Question12,
    Question13,
  },
  data() {
    return {
      started: false,
    };
  },
  methods: {
    storeProgress(data) {
      this.$emit("storeProgress", data);
    },
  },
};
</script>

<style scoped></style>
