<template>
  <div>
    <div
      class="bg-white text-black w-full rounded-xl relative"
      v-if="dataFetched"
    >
      <div class="row flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 relative md:pb-10 md:flex md:justify-end">
          <Q1Board :selectedPostits="selectedPostits" />
        </div>
        <div class="w-full md:w-2/5">
          <draggable
            class="list-group w-full h-fit md:mt-10 ml-4 md:ml-0"
            :list="basePostits"
            group="people"
            itemKey="url"
            @change="storeProgress()"
          >
            <template #item="{ element }">
              <div class="list-group-item relative mr-4 mb-4">
                <img :src="element.url" class="w-20 h-20 md:w-28 md:h-28" />
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import draggable from "vuedraggable";
import Q1Board from "@/components/Experiences/Pulse1Week/Questions/Assets/Q1Board.vue";

export default {
  name: "PulseQuestion1",
  components: {
    draggable,
    Q1Board,
  },
  created() {
    if (this.selectedPostits.length == 0 && this.basePostits.length == 0) {
      ApiService.get(apiResource.experience.getAssetsForQuestion, {
        question: "question1",
      })
        .then(({ data }) => {
          this.assets = data.data;
          this.basePostits = this.assets.postits;
          this.dataFetched = true;
        })
        .catch(() => {});
    }
  },
  data() {
    return {
      dataFetched: false,
      assets: {},
      selectedPostits: [],
      basePostits: [],
    };
  },
  methods: {
    storeProgress() {
      this.$emit("storeProgress", {
        selectedPostits: this.selectedPostits,
        basePostits: this.basePostits,
      });
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 1.75rem;
}
.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.list-group {
  padding: 20px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  margin-bottom: 0;
  border-radius: 0.25rem;
  min-height: 20px;
}
.list-group-item {
  cursor: pointer;
  position: relative;
  display: block;
}
</style>
